import React from 'react';
import Historia from './Historia/Historia'; //
import Footer from '../Footer/Footer'; //
import Testimonios from './Testimonios/Testimonios'; //
import Servicios from './Servicios/Servicios'; //

function Front() {
    return (

        <div>

        <Historia />
        <Servicios />
        <Testimonios />
        <Footer />

        </div>
    );
}

export default Front;