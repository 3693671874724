import React from 'react';
import './Historia.css'; // Asegúrate de crear este archivo CSS

function Historia() {
    const historias = [
        {
            id: 1,
            title: "La Batalla de Boqueron",
            description: "Un momento decisivo en la historia militar.",
            image: "https://www.lostiempos.com/sites/default/files/styles/noticia_detalle/public/media_imagen/2018/10/7/la_nacion.jpg?itok=Neko21BM"
        },
        {
            id: 2,
            title: "Cnl Marzana",
            description: "La lucha por Boqueron.",
            image: "https://erbol.com.bo/sites/default/files/manuelmarzana-179-21.jpg"
        }
        // Agrega más historias aquí
    ];

    return (
        <div className="historia-container">
            {historias.map(historia => (
                <div key={historia.id} className="card">
                    <img src={historia.image} alt={historia.title} className="card-img-top" />
                    <div className="card-body">
                        <h5 className="card-title">{historia.title}</h5>
                        <p className="card-text">{historia.description}</p>
                        <a href="#" className="btn btn-primary">Leer más</a>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default Historia;
